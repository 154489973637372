<template>
  <div class="header">
    <div class="saumeer">
      <div class="headermain">
        <div class="header-left">
          <i class="el-icon-location"></i>
          <span style="color: #999999">{{ location }}</span>
          <span v-if="userInfo && authToken" class="user_left">
            <span class="company">{{ userInfo.realName }}</span>
            <span
              class="companyicon NotCertifiedYet"
              v-if="userInfo.customerType === 0"
              >未认证</span
            >
            <span
              class="companyicon EnterpriseUser"
              v-if="userInfo.customerType === 1"
              >企业用户</span
            >
            <span
              class="companyicon IndividualUser"
              v-if="userInfo.customerType === 2"
              >个人用户</span
            ></span
          >
          <span
            class="company"
            v-else
            style="margin: 0px 66px 0px 35px; font-size: 12px; color: #999999"
            >您好！欢迎光临元素商城</span
          >
          <div v-if="userInfo && authToken" class="Parting_line"></div>
          <a
            v-if="userInfo && authToken"
            type="text"
            @click="logout"
            style="font-size: 12px; cursor: pointer; color: #999999"
            >退出</a
          >
          <span class="logReg" v-else
            ><a @click="goLogin">登录</a
            ><a @click="goRegiser">免费注册</a></span
          >
        </div>
        <div class="header-right">
          <el-link
            href="/userCenter/myOrder"
            class="nav-link"
            v-if="userInfo && authToken"
            >我的订单</el-link
          >
          <div v-if="userInfo && authToken" class="Parting_line"></div>
          <el-link
            href="/userCenter/myMessage"
            class="nav-link"
            v-if="userInfo && authToken"
            >我的消息</el-link
          >
          <div v-if="userInfo && authToken" class="Parting_line"></div>
          <el-link
            href="/userCenter"
            class="nav-link"
            v-if="userInfo && authToken"
            >用户中心</el-link
          >
          <p href="tel:400-605-7200" class="service-line">
            <i class="el-icon-phone-outline"></i
            ><span>服务热线 400-605-7200</span>
          </p>
          <div class="Parting_line"></div>
          <el-link href="/helpCenter" class="nav-link">帮助中心</el-link>
        </div>
      </div>
    </div>
    <div class="headersearch">
      <div class="searchclass">
        <div class="searchleft">
          <div class="ysshoplogo" @click="goindex"></div>
          <div class="ysboder" v-if="this.$route.meta.context"></div>
          <div class="hydlclass" v-if="this.$route.meta.context">
            {{ this.$route.meta.context }}
          </div>
        </div>
        <div class="searchright">
          <div class="search_info">
            <el-autocomplete
              v-model.trim="inputval"
              placeholder="商品名/品牌/货号/化合物名/CAS号"
              @keyup.enter.native="handleEnter"
              class="inputer"
              :fetch-suggestions="remoteMethod"
              popper-class="custom-autocomplete"
              @select="handleSelect"
              @focus="showHistory = true"
              @blur="hideHistory"
              ref="searchInputAuto"
            ></el-autocomplete>
            <div
              class="hotwords"
              v-if="
                $route.path === '/home' ||
                $route.path === '/searchResPage' ||
                $route.name === 'productDetails'
              "
            >
              <span
                v-for="(item, index) in hotKeysData"
                :key="index"
                @click="gothotKeys(item)"
              >
                {{ item.dictLabel }}
                <span v-if="index !== hotKeysData.length - 1">、</span>
              </span>
            </div>
            <ul
              v-if="
                showHistory &&
                filteredHistory.length &&
                (!inputval || inputval === '')
              "
              class="autocomplete_list"
              style="z-index: 9999999999999999"
            >
              <li class="operate">
                <span>最近搜索过</span>
                <span @click="clearHistory" style="cursor: pointer"
                  >清空记录</span
                >
              </li>
              <li
                v-for="(item, index) in limitedHistory"
                :key="index"
                @click="selectHistory(item)"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="search"
            class="searchinfo"
            >搜索</el-button
          >
          <el-button
            @click="Advancedsearch"
            class="Advancedclass"
            v-if="
              $route.path === '/home' ||
              $route.path === '/searchResPage' ||
              $route.name === 'productDetails' ||
              $route.name === 'advancedSearch'
            "
            ><img
              style="width: 14px; height: 14px; margin-right: 6px"
              src="../assets/icons/Advancedsearch.png"
              alt=""
            />高级搜索
          </el-button>
          <div
            class="cartcls"
            v-if="
              $route.path === '/home' ||
              $route.path === '/searchResPage' ||
              $route.name === 'productDetails' ||
              $route.name === 'advancedSearch'
            "
          >
            <el-button
              icon="el-icon-shopping-cart-2"
              @click="cartclc"
              style="width: 110px; height: 42px"
            >
              购物车</el-button
            ><span>{{ CartNum }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotKeysApi } from "@/api/index";
import {
  associationalWordApi,
  recordWordApi,
  searchResultApi,
  advancedSearchApi,
} from "@/api/search.js";
import { cartNumApi } from "@/api/home/index";
import Bus from "@/components/bus";
export default {
  props: {
    location: {
      type: String,
      default: "苏州",
    },
    companyName: {
      type: String,
      default: "苏州星云信息科技有限公司",
    },
  },
  data() {
    return {
      inputval: "",
      CartNum: 0,
      parterData: [],
      suggestions: [],
      hotKeysData: [],
      searchData: {},
      associateKeywordId: null,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      authToken: this.$cookies.get("authToken"), // 获取 token
      showHistory: false,
      searchHistory: JSON.parse(localStorage.getItem("searchHistory")) || [],
    };
  },
  watch: {
    $route(to, from) {
      this.getUserInfo();
      this.getauthToken();
      if (to.name == "searchResPage" || to.name == "productDetails") {
      } else {
        this.inputval = "";
      }
      if (to.path === "/home") {
        let authToken = this.$cookies.get("authToken");
        if (authToken) {
          this.getCartNum();
        }
      }
    },
  },
  created() {
    Bus.$on("callMethod", this.getCartNum);
    this.inputval = this.getQueryParam("keys");
    if (this.$route.path !== "/login") {
      this.getCartNum();
    }
    this.getHotKeys();
  },
  beforeDestroy() {
    Bus.$on("callMethod", this.getCartNum);
  },
  mounted() {},
  computed: {
    filteredHistory() {
      return this.searchHistory;
    },
    limitedHistory() {
      return this.filteredHistory.slice(0, 10); // 取最多十条记录
    },
  },
  methods: {
    //热词
    async getHotKeys() {
      let res = await getHotKeysApi();
      if (res.code === 200) {
        this.hotKeysData = res.data;
      }
    },
    logout() {
      this.$cookies.remove("authToken");
      localStorage.removeItem("userInfo");
      this.$router.push({ path: "/login" }, () => {});
      // 处理退出登录逻辑
    },
    getQueryParam(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },
    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    },
    getauthToken() {
      this.authToken = this.$cookies.get("authToken");
    },
    async getCartNum() {
      const authToken = this.$cookies.get("authToken");
      if (authToken) {
        let res = await cartNumApi();
        if (res.code === 200) {
          this.CartNum = res.data;
          Bus.$emit("callCartNumber", this.CartNum);
        }
      }
    },
    //回首页
    goindex() {
      this.$router.push({ path: "/home" }, () => {});
    },
    handleEnter() {
      this.search();
    },
    search() {
      searchResultApi({ keywords: this.inputval })
        .then((res) => {
          this.searchData = res.data;
          if (this.searchData.resultUrl) {
            //resultUrl存在直接跳转resultUrl详情
            this.$router.push({ path: this.searchData.resultUrl });
          } else {
            //resultUrl不存在执行nextExecute方法跳转搜索结果页
            this.nextExecute();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    nextExecute() {
      const targetPath = {
        path: "/searchResPage",
        query: {
          keys: this.inputval,
        },
      };
      if (
        this.$route.path === targetPath.path &&
        this.$route.query.keys === this.inputval
      ) {
        // 清除当前查询参数
        this.$router
          .push({ path: targetPath.path, query: { keys: null } })
          .then(() => {
            // 立即重新设置查询参数
            this.$router.push(targetPath);
          });
      } else {
        this.$router.push(targetPath);
      }
      this.saveSearch();
    },
    //往搜索历史里塞数据
    saveSearch() {
      //防止重复
      if (this.inputval && !this.searchHistory.includes(this.inputval)) {
        this.searchHistory.unshift(this.inputval);
        localStorage.setItem(
          "searchHistory",
          JSON.stringify(this.searchHistory)
        );
      }
    },
    //点击历史记录
    selectHistory(item) {
      this.searchHistory = [
        item,
        ...this.searchHistory.filter((i) => i !== item),
      ];
      this.inputval = item;
      this.showHistory = false; // 选择后隐藏历史
      this.search();
    },
    //搜索框失焦
    hideHistory() {
      setTimeout(() => {
        this.showHistory = false; // 延迟隐藏以允许点击事件
      }, 200);
    },
    //清空记录
    clearHistory() {
      this.searchHistory = [];
      localStorage.removeItem("searchHistory"); // 清除本地存储
    },
    remoteMethod(query, cb) {
      this.$refs.searchInputAuto.activated = false; //关闭下拉框
      if (query) {
        setTimeout(async () => {
          const results = await this.getList(query);
          if (results.length) {
            this.$refs.searchInputAuto.activated = true;
          }
          this.suggestions = results;
          cb(results);
        }, 500);
      } else {
        this.$refs.searchInputAuto.activated = false; //关闭下拉框
        this.suggestions = [];
        cb([]);
      }
    },
    async getList(query) {
      try {
        const response = await associationalWordApi({
          option: query,
        });
        if (response.code === 200) {
          this.parterData = response.data;
          return response.data.map((item) => ({ value: item.keyword }));
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        return [];
      }
    },
    async handleSelect(row) {
      this.parterData.forEach((item) => {
        if (item.keyword === row.value) {
          this.associateKeywordId = item.associateKeywordId;
        }
      });
      if (this.associateKeywordId) {
        let res = await recordWordApi({
          associateKeywordId: this.associateKeywordId,
        });
      }
      this.search();
    },
    //去登录
    goLogin() {
      this.$router.push({ path: "/login" }, () => {});
    },
    //去注册
    goRegiser() {
      this.$router.push({ path: "/regiSter" }, () => {});
    },
    //高级搜索
    Advancedsearch() {
      this.$router.push({ path: "/advancedSearch" }, () => {});
    },
    // 购物车
    cartclc() {
      if (this.authToken && this.userInfo) {
        this.$router.push({ path: "/cart" });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    gothotKeys(item) {
      this.inputval = item.dictLabel;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-family: Arial, sans-serif;
  font-size: 12px;
  .saumeer {
    width: 100%;
    background-color: #f2f2f2;
    font-size: 12px !important;
  }
  .headermain {
    height: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
  }
  .headersearch {
    height: 100px;
    width: 100%;
  }
  .Parting_line {
    width: 1px;
    height: 10px;
    background: #dcdcdc;
    margin: 0px 13px 0px 16px;
  }
  .header-left {
    display: flex;
    align-items: center;
    .user_left {
      margin-left: 36px;
      display: flex;
      .company {
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        margin-right: 11px;
      }
      .companyicon {
        width: 60px;
        height: 15px;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        border-radius: 8px;
        text-align: center;
        line-height: 17px;
      }
      .NotCertifiedYet {
        background: linear-gradient(0deg, #666666, #cccccc);
      }
      .EnterpriseUser {
        background: linear-gradient(0deg, #ad7f00, #ebad00);
      }
      .IndividualUser {
        background: linear-gradient(0deg, #008fff, #80c7ff);
      }
    }
    .logReg {
      font-weight: 400;
      font-size: 12px;
      width: 96px;
      display: flex;
      justify-content: space-between;
      a {
        cursor: pointer;
      }
      a:first-child {
        width: 35px;
        border-right: 1px #dcdcdc solid;
        color: #008fff;
      }
      a:last-child {
        color: #666666;
      }
    }
  }
  .searchclass {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .searchleft {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    /* width: 512px; */
    height: 100%;
  }
  .ysshoplogo {
    margin-left: 25px;
    width: 160px;
    height: 51px;
    margin-top: 5px;
    background: url(../../src/assets/images/YsLogo.svg) no-repeat 50%;
    background-size: 142px 51px;
    cursor: pointer;
  }
  .ysboder {
    height: 51px;
    width: 1px;
    background: #eeeeee;
    margin: 0 20px;
  }
  .hydlclass {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 24px;
    min-width: 110px;
    color: #333333;
  }
  .searchright {
    display: flex;
    .search_info {
      position: relative;
      .autocomplete_list {
        position: absolute;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow-y: auto;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        color: #666666;
        z-index: 1000; /* 确保在其他元素之上 */
        .operate {
          display: flex;
          justify-content: space-between;
          cursor: default;
        }
        .operate:hover {
          background-color: #fff;
        }
        li {
          padding: 10px;
          cursor: pointer;
        }
        li:hover {
          background-color: #f5f7fa;
        }
      }
    }
    ::v-deep {
      .Advancedclass {
        width: 110px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        margin: 0px 21px;
        span {
          display: flex;
          align-items: center;
        }
      }
      .inputer {
        width: 520px !important;
        height: 42px;
        position: relative;
        .el-input {
          height: 100%;
          .el-input__inner {
            border-radius: 4px 0px 0px 4px;
            border-top: 2px solid #0084ff;
            border-bottom: 2px solid #0084ff;
            border-left: 2px solid #0084ff;
            width: 520px !important;
            height: 100%;
          }
        }
      }
      .hotwords {
        position: absolute;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        margin: 5px 0px 0px 3px;
        overflow: hidden;
        white-space: nowrap;
        width: 520px;
        span {
          cursor: pointer;
        }
        span:hover {
          color: #0084ff;
        }
      }
      .searchinfo {
        width: 110px;
        height: 42px;
        border-radius: 0px 4px 4px 0px;
        background: #0084ff;
      }
    }
    .cartcls {
      position: relative;
      span {
        min-width: 13px;
        min-height: 13px;
        line-height: 13px;
        background: #ff0000;
        border-radius: 10px;
        top: -5px;
        right: -3px;
        text-align: center;
        font-size: 10px;
        position: absolute;
        color: #ffffff;
      }
    }
  }
  .header-left .el-icon-location {
    margin-right: 5px;
  }

  ::v-deep {
    .header-right {
      display: flex;
      align-items: center;
      font-size: 12px;
    }
  }

  .nav-link {
    font-size: 12px;
    color: #606266;
  }
  .service-line {
    font-weight: 400;
    font-size: 12px;
    color: #0084ff;
    margin: 0 10px;
    i {
      margin-right: 9px;
    }
  }
}
</style>
