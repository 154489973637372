import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
  },
  state: {
    currentUser: null, // 修改为 currentUser 以避免命名冲突
  },
  mutations: {
    setUser(state, user) {
      state.currentUser = user; // 同样修改为 currentUser
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit("setUser", user);
          resolve();
        }, 1000);
      });
    },
    logout({ commit }) {
      commit("setUser", null);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.currentUser, // 修改为 currentUser
    currentUser: (state) => state.currentUser, // 修改为 currentUser
  },
});
