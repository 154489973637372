import request from "@/utils/request";

export function searchResultApi(data) {
  return request({
    url: "/mall/goods/search",
    method: "post",
    data: data,
  });
}
//根据用户输入给出联想词列表，最多返回20条联想词
export function associationalWordApi(query) {
  return request({
    url: "/mall/market/associateKeyword/list",
    method: "GET",
    params: query,
  });
}
//记录用户点击联想词
export function recordWordApi(query) {
  return request({
    url: "/mall/market/associateKeyword/statistics",
    method: "GET",
    params: query,
  });
}

//商品高级搜索
export function advancedSearchApi(data) {
  return request({
    url: "/mall/goods/advancedSearch",
    method: "POST",
    data: data,
  });
}
