<template>
  <div id="app">
    <div class="my-home" v-if="loadingIndex" style="z-index: 99">
      <div class="my-home__main">
        <img
          class="my-home__loading"
          src="../public/img/loading-spin.svg"
          alt="loading"
        />
        <div class="my-home__title">努力加载中...</div>
        <div class="my-home__sub-title d">
          初次加载资源可能需要较多时间 请耐心等待
        </div>
      </div>
    </div>
    <Header
      :location="userLocation"
      :companyName="userCompany"
      v-if="!loadingIndex"
    />
    <router-view v-if="!loadingIndex" />
    <Footer v-if="!loadingIndex" />
    <sidebar v-if="!loadingIndex"></sidebar>
  </div>
</template>

<script>
import Footer from "./components/FooterView.vue";
import Header from "./components/HeaderView.vue";
import sidebar from "./components/sidebar.vue";
export default {
  name: "App",
  components: {
    Footer,
    Header,
    sidebar,
  },
  data() {
    return {
      loadingIndex: true,
      userLocation: "苏州",
      userCompany: "苏州星云信息科技有限公司",
    };
  },
  watch: {},
  created() {
    let times = 500; //加载延时loading
    let that = this;
    setTimeout(function () {
      that.loadingIndex = false;
    }, times);
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style>
* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* overflow: auto; */
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1300px;
  width: 100%;
  height: 100%;
}
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
}

.my-home {
  background-color: rgba(33, 172, 199, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.my-home__main {
  user-select: none;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.my-home__title {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}

.my-home__sub-title {
  color: #ffffff;
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(144, 147, 153, 0.3);
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
a {
  color: #ffffff;
  text-decoration: none;
}
</style>
