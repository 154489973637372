import request from "@/utils/request";

//为你推荐
export function recommendedApi() {
  return request({
    url: "/mall/market/goodsRecommend/recommend",
    method: "GET",
  });
}

//一级分类列表
export function firstClassiFicationApi() {
  return request({
    url: "/mall/category/list",
    method: "GET",
  });
}

//首页Banner
export function homeBannerApi() {
  return request({
    url: "/mall/market/swiper/banner",
    method: "GET",
  });
}

//好货优选
export function goodSelectionApi() {
  return request({
    url: "/mall/market/swiper/choice",
    method: "GET",
  });
}

//品牌推荐
export function brandRecommenApi() {
  return request({
    url: "/mall/market/swiper/brand",
    method: "GET",
  });
}

//热销榜单
export function hotsellApi() {
  return request({
    url: "/mall/market/goodsRecommend/bestSeller",
    method: "GET",
  });
}

//获取资讯分页列表
export function informationListApi(query) {
  return request({
    url: "/mall/market/info/findIndexInfoPage",
    method: "GET",
    params: query,
  });
}

//用户待办订单数量统计
export function quantityStatisticsApi() {
  return request({
    url: "/mall/order/getWaitOrderByUser/u",
    method: "GET",
  });
}

//获取购物车小图标商品种类数量
export function cartNumApi() {
  return request({
    url: "/mall/shoppingCart/getCartGoodsAmount/u",
    method: "GET",
  });
}
