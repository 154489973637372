<template>
  <div>
    <el-button @click="copyText" :class="buttonClass" :type="buttonType">
      {{ buttonText }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: "复制文本",
    },
    successMessage: {
      type: String,
      default: "复制成功！",
    },
    buttonClass: {
      type: String,
      default: "", // 接收来自父组件的样式类
    },
    buttonType: {
      type: String,
      default: "", // 接收来自父组件的type
    },
  },
  data() {
    return {
      copySuccess: false,
      copyMessage: this.successMessage,
    };
  },
  methods: {
    async copyText() {
      try {
        if (navigator.clipboard) {
          // 使用 Clipboard API 将文本复制到剪贴板
          await navigator.clipboard.writeText(this.text);
          this.copySuccess = true;
          this.$message.success(this.successMessage);
        } else {
          // 作为备选方案，使用临时文本区域复制
          // 作为备选方案，使用临时文本区域复制
          const textarea = document.createElement("textarea");
          textarea.value = this.text;
          document.body.appendChild(textarea);
          textarea.select();
          try {
            // 尝试使用 execCommand 复制文本
            document.execCommand("copy"); // 旧方法，可能会被标记为过时
            this.copySuccess = true;
            this.$message.success(this.successMessage);
          } catch (err) {
            console.error("复制失败", err);
            this.copySuccess = false;
            this.$message.error("复制失败");
          } finally {
            document.body.removeChild(textarea);
          }
        }
      } catch (err) {
        console.error("复制失败", err);
        this.copySuccess = false;
        this.$message.error("复制失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* 这里可以添加样式 */
</style>
