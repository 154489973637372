import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/mall/login",
    method: "post",
    data: data,
  });
}

export function logout(data) {
  return request({
    url: "/mall/logout",
    method: "post",
    data: data,
  });
}

//获取图形验证码
export function getKaptCha() {
  return request({
    url: "/mall/login/getKaptcha",
    method: "get",
  });
}

//获取手机验证码
export function getPhoneCode(data) {
  return request({
    url: "/mall/login/getSmsCode",
    method: "PUT",
    data: data,
  });
}

//通过短信登录
export function dxLogin(data) {
  return request({
    url: "/mall/login_phone",
    method: "post",
    data: data,
  });
}

//获取用户基本信息
export function getUserInfoApi() {
  return request({
    url: "/mall/account/getUserInfo/u",
    method: "GET",
  });
}

//获取当前用户的账户安全信息
export function getSecurityInfoApi() {
  return request({
    url: "/mall/account/security/getSecurityInfo/u",
    method: "GET",
  });
}

// 根据当前用户，查询该客户下所有用户的余额和信用额度
export function getCheckCreditLimitApi() {
  return request({
    url: "/mall/account/findCustomerBalances/u",
    method: "GET",
  });
}

// 查询用户待办订单数据按客户分
export function getCountCustomerOrderByUserApi() {
  return request({
    url: "/mall/order/countCustomerOrderByUser/u",
    method: "GET",
  });
}

// 底部导航栏-帮助文档
export function getFindBottomHelpInfoTypeListApi(query) {
  return request({
    url: "/mall/market/helpInfo/findBottomHelpInfoTypeList",
    method: "GET",
    params: query,
  });
}

//搜索推荐词
export function getHotKeysApi() {
  return request({
    url: "/mall/baseInfo/hotKeys",
    method: "GET",
  });
}

// 底部导航栏-友情链接
export function getFriendlyLinksApi() {
  return request({
    url: "/mall/baseInfo/friendlyLinks",
    method: "GET",
  });
}

// 微信登录的跳转链接
export function getWechatLoginUrlApi() {
  return request({
    url: "/weixin_login/url",
    method: "GET",
  });
}

// 微信登录的跳转链接
export function getWechatLoginApi(query) {
  return request({
    url: "/weixin_login",
    method: "GET",
    params: query,
  });
}

// 显示绑定微信用户的验证码
export function getWechatKaptchaApi() {
  return request({
    url: "/weixin_login/getKaptcha",
    method: "GET",
  });
}

// 获取手机验证码，并发送到手机，需要验证图形验证码
export function getWechatSmsCodeApi(data) {
  return request({
    url: "/weixin_login/getSmsCode",
    method: "PUT",
    data: data,
  });
}

// 微信绑定用户最后一步，如果不存在用户，则直接创建，如果存在用户，则返回对应用户
export function getWechatBindUserApi(data) {
  return request({
    url: "/weixin_login/bindUser",
    method: "POST",
    data: data,
  });
}

// 支付宝登录的跳转链接
export function getZfbLoginUrlApi() {
  return request({
    url: "/zhifubao_login/url",
    method: "GET",
  });
}

// 支付宝登录的跳转链接
export function getZfbLoginApi(query) {
  return request({
    url: "/zhifubao_login",
    method: "GET",
    params: query,
  });
}

// 显示绑定支付宝用户的验证码
export function getZfbKaptchaApi() {
  return request({
    url: "/zhifubao_login/getKaptcha",
    method: "GET",
  });
}

// 获取手机验证码，并发送到手机，需要验证图形验证码
export function getZfbSmsCodeApi(data) {
  return request({
    url: "/zhifubao_login/getSmsCode",
    method: "PUT",
    data: data,
  });
}

// 支付宝绑定用户最后一步，如果不存在用户，则直接创建，如果存在用户，则返回对应用户
export function getZfbBindUserApi(data) {
  return request({
    url: "/zhifubao_login/bindUser",
    method: "POST",
    data: data,
  });
}

// 获取首页的销售
export function getSalesFromHomePageApi() {
  return request({
    url: "/mall/baseInfo/findHomeSales",
    method: "GET",
  });
}
